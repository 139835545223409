import axios from "axios";

const BASE_URL = "https://webback.opencurtainscasting.com/";

export const signupURL = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-type": "application/json",
  },
});

export const API = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const multipartAPI = axios.create({
  baseURL: BASE_URL,
  timeout: 400000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  },
});

multipartAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//login
export const SignIn = async (body) => {
  try {
    const res = await signupURL.post(`/user/login`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//sinup
export const SignUp = async (body) => {
  try {
    const res = await signupURL.post(`/user/register`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

// // Define the user ID as a constant
// const USER_ID = 50;

// Function to update the ABOUT section
export const updateAboutContent = async (ID, content) => {
  try {
    const response = await API.put(`/actor/updateAbout/${ID}`, {
      about: content,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update ABOUT section:", error);
    throw error;
  }
};

// Function to update the INTEREST section
export const updateInterestContent = async (ID, content) => {
  try {
    const response = await API.put(`/actor/updateInterest/${ID}`, {
      interestedIn: content,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update INTEREST section:", error);
    throw error;
  }
};

// Function to update the TALENTS section
export const updateTalentsContent = async (ID, content) => {
  try {
    const response = await API.put(`/actor/updateTalents/${ID}`, {
      talents: content,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update TALENTS section:", error);
    throw error;
  }
};

// Function to get user details
export const getUserDetails = async (userId) => {
  try {
    const response = await API.get(`/actor/getUser/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch user details:", error);
    throw error;
  }
};

// Function to update the HOBBIES section
export const updateHobbiesContent = async (ID, content) => {
  try {
    const response = await API.put(`/actor/updateHobbies/${ID}`, {
      hobbies: content,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update HOBBIES section:", error);
    throw error;
  }
};

// Function to update AGE section
export const updateAgeContent = async (ID, age) => {
  try {
    const response = await API.put(`/actor/updateAge/${ID}`, { age });
    return response.data;
  } catch (error) {
    console.error("Failed to update AGE section:", error);
    throw error;
  }
};

// Function to update LOCATION section
export const updateLocationContent = async (ID, location) => {
  try {
    const response = await API.put(`/actor/updateLocation/${ID}`, {
      location,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update LOCATION section:", error);
    throw error;
  }
};

// Function to update ETHNICITY section
export const updateEthnicityContent = async (ID, ethnicity) => {
  try {
    const response = await API.put(`/actor/updateEthnicity/${ID}`, {
      ethnicity,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update ETHNICITY section:", error);
    throw error;
  }
};

// Function to update EYE COLOR section
export const updateEyeColorContent = async (ID, eyeColor) => {
  try {
    const response = await API.put(`/actor/updateEyeColor/${ID}`, {
      eyeColor,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update EYE COLOR section:", error);
    throw error;
  }
};

// Function to update HAIR COLOR section
export const updateHairColorContent = async (ID, hairColor) => {
  try {
    const response = await API.put(`/actor/updateHairColor/${ID}`, {
      hairColor,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update HAIR COLOR section:", error);
    throw error;
  }
};

// Function to update HEIGHT section
export const updateHeightContent = async (ID, height) => {
  try {
    const response = await API.put(`/actor/updateHeight/${ID}`, {
      height,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update HEIGHT section:", error);
    throw error;
  }
};

// Function to update WEIGHT section
export const updateWeightContent = async (ID, weight) => {
  try {
    const response = await API.put(`/actor/updateWeight/${ID}`, {
      weight,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update WEIGHT section:", error);
    throw error;
  }
};

// Function to update specific social network links
export const updateSocialNetworkLink = async (ID, platform, link) => {
  try {
    let response;
    switch (platform) {
      case "facebook":
        response = await API.put(`/actor/updateFbLink/${ID}`, {
          fbLink: link,
        });
        break;
      case "instagram":
        response = await API.put(`/actor/updateInstagram/${ID}`, {
          instagramLink: link,
        });
        break;
      case "xlink":
        response = await API.put(`/actor/updateXLink/${ID}`, {
          xLink: link,
        });
        break;
      case "tiktok":
        response = await API.put(`/actor/updateTikTokLink/${ID}`, {
          tikTokLink: link,
        });
        break;
      case "youtube":
        response = await API.put(`/actor/updateYoutubeLink/${ID}`, {
          youtubeLink: link,
        });
        break;
      default:
        throw new Error("Unsupported platform");
    }
    return response.data;
  } catch (error) {
    console.error(`Failed to update ${platform} link:`, error);
    throw error;
  }
};

// Function to update the user's headshot image
export const updateUserImage = async (userId, formData) => {
  try {
    const response = await multipartAPI.put(
      `/actor/updateUserImage/${userId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to update user image:", error);
    throw error;
  }
};

//review

export const getAllReviews = async () => {
  try {
    const res = await API.get(`/review/getAll`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addReviews = async (id, body) => {
  try {
    const res = await API.post(`/review/create/${id}`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//director
export const getDirectorDetails = async (userId) => {
  try {
    const response = await API.get(`/director/getDirector/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch user details:", error);
    throw error;
  }
};

//producer
export const getProducerDetails = async (userId) => {
  try {
    const response = await API.get(`/producer/getProducer/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch user details:", error);
    throw error;
  }
};

//crew
export const getCrewDetails = async (userId) => {
  try {
    const response = await API.get(`/crew/getCrewMember/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch user details:", error);
    throw error;
  }
};

//casting calls

export const getApprovedCastingCalls = async () => {
  try {
    const response = await API.get(`/casting/getCastingsListByStatus/approved`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch castings details:", error);
    throw error;
  }
};

export const getCastingCallsForCreator = async () => {
  try {
    const response = await API.get(
      `/casting/getCastingListingForCastingUserToken`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch castings details:", error);
    throw error;
  }
};

export const addCastingCall = async (body) => {
  try {
    const res = await API.post(`/casting/registerWithRoles`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCastingCallByID = async (id) => {
  try {
    const response = await API.get(`/casting/getCasting/${id}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch casting details:", error);
    throw error;
  }
};

export const editCastingCall = async (id, body) => {
  try {
    const res = await API.put(`/casting/updateCastingDetails/${id}`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const addRoleToCastingCall = async (body) => {
  try {
    const res = await API.post(`/role/newRole`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRole = async (id) => {
  try {
    const res = await API.delete(`/role/deleteRole/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const editRole = async (id, body) => {
  try {
    const res = await API.put(`/role/updateRole/${id}`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getAppliedRoleListForUser = async (castingId, userId) => {
  try {
    const res = await API.get(
      `apply/getRoleList/casting/${castingId}/user/${userId}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const apply = async (body) => {
  try {
    const res = await API.post(`/apply/new`, body);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//actors
export const getAllActors = async () => {
  try {
    const response = await API.get(`/actor/getAllUsers`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch actors details:", error);
    throw error;
  }
};

export const getActorsById = async (id) => {
  try {
    const response = await API.get(`/actor/getUser/${id}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch actor details:", error);
    throw error;
  }
};

// Function to update the FIRST NAME
export const updateFirstName = async (ID, firstName) => {
  try {
    const response = await API.put(`/user/updateFirstName/${ID}`, {
      firstName: firstName,
    });
    if (response.data.success) {
      console.log(response.data.message);
      return response.data.updatedUser;
    } else {
      throw new Error("Failed to update first name");
    }
  } catch (error) {
    console.error("Failed to update FIRST NAME:", error);
    throw error;
  }
};

// Function to update the LAST NAME
export const updateLastName = async (ID, lastName) => {
  try {
    const response = await API.put(`/user/updateLastName/${ID}`, {
      lastName: lastName,
    });
    if (response.data.success) {
      console.log(response.data.message);
      return response.data.updatedUser;
    } else {
      throw new Error("Failed to update last name");
    }
  } catch (error) {
    console.error("Failed to update LAST NAME:", error);
    throw error;
  }
};

export const updateEmail = async (ID, newEmail) => {
  try {
    const response = await API.put(`/user/updateEmail/${ID}`, {
      email: newEmail,
    });

    if (response.data.success) {
      console.log("Email updated successfully:", response.data.message);
      return response.data.updatedUser; // Return the updated user data
    } else {
      throw new Error("Failed to update email");
    }
  } catch (error) {
    console.error("Failed to update email:", error);
    throw error;
  }
};

export const updateContactNumber = async (ID, newContactNumber) => {
  try {
    const response = await API.put(`/user/updateContactNo/${ID}`, {
      contactNumber: newContactNumber,
    });

    if (response.data.success) {
      console.log(
        "Contact number updated successfully:",
        response.data.message
      );
      return response.data.updatedUser; // Return the updated user data
    } else {
      throw new Error("Failed to update contact number");
    }
  } catch (error) {
    console.error("Failed to update contact number:", error);
    throw error;
  }
};

// Function to update the ABOUT section for a director
export const updateDirectorAboutContent = async (ID, content) => {
  try {
    const response = await API.put(`/director/updateAbout/${ID}`, {
      about: content,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update Director's ABOUT section:", error);
    throw error;
  }
};

export const updateDirectorCategoryContent = async (ID, content) => {
  try {
    const response = await API.put(`/director/updateCategory/${ID}`, {
      category: content,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update Director's CATEGORY section:", error);
    throw error;
  }
};

export const updateDirectorCompanyName = async (ID, companyName) => {
  try {
    const response = await API.put(`/director/updateCompanyName/${ID}`, {
      companyName,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update Director's Company Name:", error);
    throw error;
  }
};

export const updateDirectorLocation = async (ID, location) => {
  try {
    const response = await API.put(`/director/updateLocation/${ID}`, {
      location,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to update Director's Location:", error);
    throw error;
  }
};

// get subscription plans
export const getSubscriptionPlans = async () => {
  try {
    const response = await API.get(`/subscriptionPackage/getAll`);
    // const response = await axios.get(
    //   "https://admin.kid.hasthiya.org/subscriptionPackage/getAll"
    // );
    return response.data;
  } catch (error) {
    console.error("Failed to get subscription plans:", error);
    throw error;
  }
};
